
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import CModal from "@/components/modal.vue";

const VHistoricObservation = defineComponent({
  name: "VHistoricObservation",
  components: { CModal },
  setup() {
    const store = useStore();
    const router = useRouter();

    const _id = Number(router.currentRoute.value.params?._id);
    const item = computed(() => store.state.user.appointment.list.find((item) => item.id === _id));

    function handleClose() {
      router.back();
    }

    return { item, handleClose };
  },
});

export default VHistoricObservation;
